var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-select",
    _vm._b(
      {
        attrs: {
          showSearch: true,
          filterOption: false,
          allowClear: true,
          loading: _vm.productOption.fetching,
          disabled: _vm.disabled,
          value: _vm.value
        },
        on: {
          search: _vm.searchProduct,
          input: _vm.handleChange,
          change: _vm.handleChange
        }
      },
      "a-select",
      _vm.$attrs,
      false
    ),
    _vm._l(_vm.productOption.data, function(item, index) {
      return _c(
        "a-select-option",
        {
          key: index,
          attrs: { value: item.id },
          on: {
            click: function($event) {
              return _vm.onMeta(item)
            }
          }
        },
        [
          _c(
            "a-tooltip",
            [
              _c("template", { slot: "title" }, [
                _vm._v(" " + _vm._s(item[_vm.optLabel]) + " ")
              ]),
              _vm._v(" " + _vm._s(item[_vm.optLabel]) + " ")
            ],
            2
          )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }