




























/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { debounce } from "@/helpers/debounce";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataListProduct } from "@/models/interface/logistic.interface";
import { logisticServices } from "@/services/logistic.service";
import { Component, Vue } from "vue-property-decorator";

const ProductSelectProps = Vue.extend({
  props: {
    value: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    optLabel: {
      type: String,
      default: "code",
    },
    query: {
      type: String,
      default: undefined,
    },
  },
});

@Component
export default class ProductSelect extends ProductSelectProps {
  productOption = {
    data: [] as DataListProduct[],
    search: "",
    fetching: true,
    initialData: [] as DataListProduct[],
  };

  async created() {
    await this.getProductList(true);
    if (this.value && !this.productOption.data.find(c => c.id === this.value)) {
      this.fetchMoreProduct();
    }
  }

  async getProductList(firstLoad = false) {
    try {
      this.productOption.fetching = true;
      const param: RequestQueryParamsModel = {
        limit: 20,
        page: 0,
        search: "",
      };
      const searchBy: string[] = [];
      if (this.query) {
        searchBy.push(this.query);
      }
      if (this.productOption.search) {
        searchBy.push(
          `code~*${this.productOption.search}*_OR_name~*${this.productOption.search}*`
        );
      }
      param.search = searchBy.join("_AND_");
      const res = await logisticServices.listProduct(param);
      this.productOption.data = res.data;
      if (firstLoad) {
        this.productOption.initialData = res.data;
      }
      this.productOption.fetching = false;
    } catch (error) {
      this.productOption.fetching = false;
    }
  }

  searchProduct(value: string): void {
    debounce(() => {
      this.productOption.search = value;
      this.getProductList();
    });
  }

  handleChange(e: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
  }

  onMeta(value: DataListProduct): void {
    this.$emit("meta", value);
  }

  resetOptionList(): void {
    this.productOption.data = this.productOption.initialData;
  }

  /**
   * Fetch product list from API until matching with @this value
   */
  async fetchMoreProduct() {
    try {
      this.productOption.fetching = true;
      let totalPage = 1;
      const param = {
        limit: 20,
        page: 1,
      };
      while (
        !this.productOption.data.find(c => c.id === this.value) &&
        param.page <= totalPage
      ) {
        const productOptions = await logisticServices.listProduct(param);
        totalPage = Math.ceil(productOptions.totalElements / 20);
        this.productOption.data = this.productOption.data.concat(
          productOptions.data
        );
        param.page++;
      }
      this.productOption.fetching = false;
    } catch (error) {
      this.productOption.fetching = false;
    }
  }
}
